import debug from 'debug';

const api = async (endpoint) => {
  const logger = debug('api');
  try {
    const response = await fetch(`/api/${endpoint}`);
    if (!response.ok) {
      return false;
    }
    try {
      const json = await response.json();
      return json || null;
    } catch (e) {
      logger(`Error parsing json response from ${endpoint}: ${e}`);
      return false;
    }
  } catch (e) {
    logger(`Error fetching data from ${endpoint}: ${e}`);
    return false;
  }
};

export default api;
